<template>

<div class="content" :class="{'is-active': isActive}">

	<router-view :key="$route.fullPath" />

</div>

</template>

<script>

export default {

	props: ['isActive']

}

</script>

<style scoped>

.content {
	position: absolute;
	left: -100%;
	top: 0px;
	bottom: 0px;
	background-color: #fff;
	border-right: 1px solid #ccc;
	width: 664px;
	padding-left: 64px;
	z-index: 2;
	transition: left 300ms ease-in-out;
	padding: 0px 0px 0px 64px;
}

.is-guest .content {
	padding: 0px;
	border-width: 0px;
	left: 0px;
	top: 0px;
	bottom: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	right: 0px;
	width: auto;
	height: auto;
	background-color: transparent;
}

.content.is-active {
	left: 0%;
}

</style>
